<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import "@vueform/multiselect/themes/default.css";

import "prismjs";
import "prismjs/themes/prism.css";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      title: "Skills",
      page: 1,
      perPage: 10,
      pages: []
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    ...mapGetters({
      getSkills: "skills/getSkills",
      userData: "auth/user",
    }),
    displayedPosts() {
      return this.paginate(this.getSkills);
    },
    isAdmin(){
      return this.userData && this.userData.role_id == 1 ? true : false
    },
  },
  async mounted() {
    await this.setSkills();
    await this.setPages()
  },
  methods: {
    ...mapActions({
      setSkills: "skills/setSkills",
      deleteSkill: "skills/deleteSkill",
    }),
    getDate(date) {
      return moment(date).format("MM/DD/YY");
    },
    deleteskillData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteSkill(id)
            .then(() => {
                this.$toast.open({
                  message: "skill Deleted.",
                  type: "success",
                }),
                this.setSkills();
            })
            .catch(() => {
              Swal.fire("Oops...", "Something went wrong", "error");
            });
        }
      });
    },
    setPages() {
      let numberOfPages = Math.ceil(this.getSkills.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-Skills')" />
    <div class="row">
      <div class="col-xl-12">
        

        <div class="card mt-4">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Skills</h4>
            <div class="flex-shrink-0">
              <router-link
                to="/admin/skills/add"
                class="btn btn-primary waves-effect waves-light"
              >
                Add Skills
              </router-link>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <div class="table-responsive table-card">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Skill Name</th>
                    
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(skill, index) in getSkills" :key="index">
                    <td>{{ skill.skills_id }}</td>
                    <td>{{ skill.skill_name }}</td>
                    <td>
                      <div class="hstack gap-3 flex-wrap">
                        <router-link class="link-success fs-15" :to="{name: 'Editskill',params: { id: skill.skills_id },}"
                          ><i class="ri-edit-2-line"></i></router-link>

                        <a v-if="isAdmin"
                          href="javascript:void(0);"
                          @click="deleteskillData(skill.skills_id)"
                          class="link-danger fs-15"
                          ><i class="ri-delete-bin-line"></i
                        ></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-end pt-4">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                    Previous
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li :class="{
                                  active: pageNumber == page,
                                  disabled: pageNumber == '...',
                                }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                      @click="page = pageNumber">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                    Next
                  </a>
                </div>
              </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>

    <!--end row-->
  </Layout>
</template>
